import { _affiliatesApiClient } from '@features/affiliates-portal/api/_affiliatesApiClient';
import { _bankTransactionsApiClient } from '@features/backoffice/accounting/transactions/api/_bankTransactionsApiClient';
import { _digitalTextbooksApiClient } from '@features/backoffice/digital-textbooks/api/_digitalTextbooksApiClient';
import { _problemsImportApiClient } from '@features/backoffice/problems-import/api/_problemsImportApiClient';
import { _quizzesApiClient } from '@features/frontoffice/quiz/data/_quizzesApi.client';
import { _cartApiClient } from '@features/webshop/cart/api/cartApiClient';

export const apiClient = {
	quizzes: _quizzesApiClient,
	problemsImport: _problemsImportApiClient,
	bankTransactions: _bankTransactionsApiClient,
	digitalTextbooks: _digitalTextbooksApiClient,
	cart: _cartApiClient,
	affiliates: _affiliatesApiClient,
};
