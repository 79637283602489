import cartFragment from '../fragments/cart';

export const addToCartMutation = /* GraphQL */ `
	mutation addToCart($cartId: ID!, $lines: [CartLineInput!]!) {
		cartLinesAdd(cartId: $cartId, lines: $lines) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const createCartMutation = /* GraphQL */ `
	mutation createCart(
		$lineItems: [CartLineInput!]
		$attributes: [AttributeInput!]
		$discountCodes: [String!] = ""
		$buyerEmail: String
	) {
		cartCreate(
			input: {
				lines: $lineItems
				attributes: $attributes
				buyerIdentity: { email: $buyerEmail }
				discountCodes: $discountCodes
			}
		) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const editCartItemsMutation = /* GraphQL */ `
	mutation editCartItems($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
		cartLinesUpdate(cartId: $cartId, lines: $lines) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const removeFromCartMutation = /* GraphQL */ `
	mutation removeFromCart($cartId: ID!, $lineIds: [ID!]!) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const addDiscountCodesMutation = /* GraphQL */ `
	mutation addDiscountCode($cartId: ID!, $discountCodes: [String!]) {
		cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const updateCartAttributesMutation = /* GraphQL */ `
	mutation updateCartAttributes($attributes: [AttributeInput!]!, $cartId: ID!) {
		cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;
